<template>
  <div>
    <header class="is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap">
      <div class="is-flex">
        <div>
          <p v-if="warehouse.category_name" class="has-text-weight-bold has-text-secondary">{{ $t(`warehouse.${warehouse.category_name.toLowerCase()}`) }}</p>
          <h2 class="is-size-5 has-text-weight-bold has-text-dark">{{ warehouse.product_name }}</h2>
          <div class="is-flex is-align-items-center">
            <span class="has-text-weight-bold is-size-7 mr-2" :class="{
                    'has-text-success': warehouse.qty > 0,
                    'has-text-danger': warehouse.qty <= 0,
                  }">
              {{ $number(warehouse.qty) }} {{ warehouse.unit }}
            </span>
            <b-dropdown class="ml-2" v-if="canEditWarehouse">
              <b-button type="is-secondary is-light"
                        slot="trigger" class="px-4" icon-left="dots-horizontal" size="is-small">
              </b-button>

              <b-dropdown-item @click="editWarehouse">{{ $t('edit') }}</b-dropdown-item>
              <b-dropdown-item @click="deleteWarehouse">{{ $t('delete') }}</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div class="is-flex is-flex-wrap-wrap" v-if="canAddWarehouse">
        <b-button class="mr-2 my-2" type="is-primary" @click="useModal = true">{{ $t('warehouse.use_item') }}</b-button>

        <b-dropdown class="mr-2 my-2" position="is-bottom-left">
          <b-button type="is-success" slot="trigger" icon-left="plus">{{ $t('warehouse.add_item') }}</b-button>

          <b-dropdown-item custom paddingless :focusable="false">
            <restock-item :warehouse="warehouse" @submit="getData"/>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown class="my-2 ml-0" position="is-bottom-left">
          <b-button type="is-danger" slot="trigger" icon-left="minus">{{ $t('warehouse.remove_item') }}</b-button>

          <b-dropdown-item custom paddingless :focusable="false">
            <remove-item :warehouse="warehouse" @submit="getData"/>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </header>

    <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap mt-3">
      <div class="is-flex is-align-items-center">
        <b-datepicker
            :placeholder="$t('date')"
            icon="calendar-text"
            locale="id-ID"
            v-model="selectedDate"
            class="mr-2 mt-2"
            @input="handleChangeDate"
            range>
        </b-datepicker>
        <sg-select label="label" :items="types" v-model="selectedType" class="mt-2" @input="handleChangeType"></sg-select>
      </div>
      <div class="is-flex is-align-items-center mt-2">
        Tampilkan
        <sg-select :items="limitFilter" v-model="limit" class="mx-2" @input="handleChangeFilter"></sg-select>
        data
      </div>
    </div>
    <div class="has-text-right mt-2">
      <b-button type="is-text"
                class="has-shadow has-text-info"
                @click="resetFilter">
        Reset Filter
      </b-button>
    </div>

    <!--    <div class="is-flex is-align-items-center">-->
    <!--      <b-input icon="magnify" placeholder="Search item" class="mr-4"/>-->
    <!--    </div>-->

    <div class="b-table custom-table my-5">
      <div class="table-wrapper">
        <table class="table is-bordered">
          <thead>
          <tr class="header-row">
            <th>{{ $t('date') }}</th>
            <th>{{ $t('warehouse.officer') }}</th>
            <th>{{ $t('action') }}</th>
            <th>{{ $t('warehouse.qty') }}</th>
            <th>{{ $t('desc') }}</th>
            <th style="width: 100px;" v-if="canEditWarehouse"></th>
          </tr>
          </thead>
          <tbody v-if="sortedData && sortedData.length">
          <tr v-for="(item, key) in sortedData" :key="key">
            <td v-if="item.header" colspan="6" class="subheader-row">{{ $dateFormat(item.date) }}</td>
            <td v-if="!item.header">{{ $timeFormat(item.date) }}</td>
            <td v-if="!item.header">{{ item.username || '-' }}</td>
            <td v-if="!item.header" :class="{
              'has-text-info': item.type === 'used' || item.type === 'synth',
              'has-text-danger': item.type === 'removed',
              'has-text-success': item.type === 'stored',
            }">{{ actionLabel(item) }}
            </td>
            <td v-if="!item.header">{{ item.qty }}</td>
            <td v-if="!item.header">{{ item.desc || '-' }}</td>
            <td v-if="!item.header && canEditWarehouse" class="has-text-centered is-flex">
              <b-button type="is-secondary is-light" class="mr-2" @click="editHistory(item)" icon-left="pencil-outline"></b-button>
              <b-button type="is-danger is-light" @click="deleteHistory(item)" icon-left="trash-can-outline"></b-button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr class="is-empty">
            <td colspan="6"><p data-v-4b2a0174="" class="has-text-centered">{{ $t('no') }} Data</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-pagination
        v-show="totalItem"
        class="pagination-borderless my-5"
        :total="totalItem"
        v-model="page"
        @change="getData"
        order="is-centered"
        :per-page="10"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">
      <b-pagination-button
          slot="previous"
          slot-scope="props"
          :page="props.page">
        Prev
      </b-pagination-button>

      <b-pagination-button
          slot="next"
          slot-scope="props"
          :page="props.page">
        Next
      </b-pagination-button>
    </b-pagination>

    <!--  Modal Edit Warehouse -->

    <b-modal
        v-model="editWarehouseModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <update-item :default-value="warehouse" is-modal @close="props.close"/>
      </template>
    </b-modal>


    <!--  Modal Use Item -->

    <b-modal
        v-model="useModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <use-item :warehouse="warehouse" is-modal @close="props.close"/>
      </template>
    </b-modal>

    <!--  Update History Item -->

    <b-modal
        v-model="historyModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <update-history :default-value="history" is-modal @submit="getData" @close="props.close"/>
      </template>
    </b-modal>

  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";

export default {
  name: "WarehouseDetail",
  components: {
    SgSelect,
    UseItem: () => import("@/components/Farm/Warehouse/UseItem"),
    RemoveItem: () => import("@/components/Farm/Warehouse/RemoveItem"),
    RestockItem: () => import("@/components/Farm/Warehouse/RestockItem"),
    UpdateItem: () => import("@/components/Farm/Warehouse/UpdateItem"),
    UpdateHistory: () => import("@/components/Farm/Warehouse/UpdateHistory"),
  },
  computed: {
    types() {
      return [
        {
          value: '',
          label: this.$t('all') + ' ' + this.$t('category')
        },
        {
          value: 'used',
          label: this.$t('warehouse.use_item')
        },
        {
          value: 'stored',
          label: this.$t('warehouse.restocked')
        },
        {
          value: 'removed',
          label: this.$t('warehouse.removed')
        },
      ]
    },
    limitFilter() {
      return [
        10, 25, 50, 100
      ]
    },
    canAddWarehouse() {
      return this.$store.getters["farm/canAddWarehouse"]
    },
    canEditWarehouse() {
      return this.$store.getters["farm/canEditWarehouse"]
    },
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    warehouse_id() {
      return this.$route.params.warehouse_id
    },
    refresh_data() {
      return this.$store.state.farm.warehouse.refresh_data
    },
  },
  data() {
    return {
      selectedDate: [],
      selectedType: {
        value: '',
        label: this.$t('all') + ' ' + this.$t('category')
      },
      limit: 10,

      useModal: false,
      editWarehouseModal: false,
      warehouse: {},

      historyModal: false,
      history: {},

      histories: [],
      sortedData: [],

      totalItem: 0,
      page: 1,
      totalPage: 0,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    actionLabel(item) {
      if (item.type === 'used') return this.$t('warehouse.used_for') + ' ' + item.pond_name
      else if (item.type === 'stored') return this.$t('warehouse.restocked')
      else if (item.type === 'removed') return this.$t('warehouse.removed')
      else if (item.type === 'synth') return this.$t('warehouse.used_for') + ' ' + this.$t('warehouse.synthesize')
    },

    editWarehouse() {
      this.editWarehouseModal = true
    },

    resetFilter() {
      this.page = 1
      this.selectedDate = []
      this.selectedType = this.types[0]
      this.limit = 10
      this.getData()
    },

    handleChangeDate() {
      this.page = 1
      this.getData()
    },

    handleChangeFilter() {
      this.page = 1
      this.getData()
    },

    handleChangeType() {
      this.page = 1
      this.getData()
    },

    async getData() {
      this.$loading()
      let res = await this.$store.dispatch('farm/getWarehouseDetail', {
        farm_id: this.farm_id,
        warehouse_id: this.warehouse_id,
      })

      if (!res.error && res.data) {
        this.warehouse = res.data

        this.histories = []
        this.sortedData = []

        let payload = {
          page: this.page,
          warehouse_id: this.warehouse_id,
          farm_id: this.farm_id,
          type: this.selectedType.value,
          limit: this.limit,
        }

        if (this.selectedDate.length) {
          payload.from = this.$startDay(this.selectedDate[0])
          payload.to = this.$endDay(this.selectedDate[1])
        }

        let history = await this.$store.dispatch('farm/getWarehouseHistory', payload)

        if (!history.error && history.data && history.data.data && history.data.paging) {
          this.totalPage = history.data.paging.total_page
          this.totalItem = history.data.paging.total_page * 10

          this.histories = history.data.data.reduce((a, b) => {
            let date = this.$moment(b.created_at).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            }).valueOf()

            let find = a.find(e => e.date === date)

            b.date = b.created_at

            if (!find) {
              a.push({
                date: date,
                items: [b]
              })
            } else {
              find.items.push(b)
            }

            return a
          }, [])

          this.sortedData = this.histories.reduce((a, b) => {
            a.push({
              date: b.date,
              header: true
            })

            a = a.concat(b.items.sort((a, b) => {
              if (b.created_at === a.created_at) return a.pond_name.localeCompare(b.pond_name, undefined, {
                sensitivity: 'base',
                numeric: true,
              })
              return b.created_at > a.created_at ? 1 : -1
            }))

            return a
          }, [])
        }
      }

      this.$store.dispatch('farm/refreshWarehouse', false)

      this.$loading(false)
    },

    editHistory(item) {
      this.history = this._.cloneDeep(item)
      this.historyModal = true
    },

    async deleteWarehouse() {
      this.$delete(async () => {
        this.$loading()

        await this.$store.dispatch('farm/deleteWarehouse', this.warehouse_id)

        this.$loading(false)
        this.$router.push('/farm/' + this.farm_id + '/warehouse')
      })
    },

    async deleteHistory(item) {
      this.$delete(async () => {
        this.$loading()

        let payload = {
          warehouse_id: item.warehouse_id,
          used_sub_id: item.used_sub_id,
        }

        if (item.type === 'stored') await this.$store.dispatch('farm/deleteRestockItem', payload)
        else if (item.type === 'removed') await this.$store.dispatch('farm/deleteRemoveItem', payload)
        else if (item.type === 'used') await this.$store.dispatch('farm/deleteUseItem', payload)

        this.$loading(false)
        this.getData()
      })
    },
  }
}
</script>
